import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import type { JSX } from 'react';
import { H3, P } from '../typography';
import { ClickableBulletWrapper } from './Bullets';

export interface MixedBulletContentProps {
  bulletContent?: JSX.Element | undefined;
  heading: string | undefined;
  text?: string | undefined;
  clickable?: boolean | undefined;
  action?:
    | {
        href?: string | undefined;
        children?: React.ReactNode | undefined;
        onClick?: React.MouseEventHandler | undefined;
      }
    | undefined;
  index: number;
}

export const BulletMixedContent: React.FC<MixedBulletContentProps> = ({
  bulletContent,
  heading,
  text,
  action,
  clickable,
}) => (
  <Box alignItems="center" justifyContent="center" width="100%" p={3} mb={4}>
    <ClickableBulletWrapper clickable={clickable} action={action}>
      {bulletContent && (
        <Box width="100%" alignItems="center" p={2}>
          <Box alignItems="center" justifyContent="center">
            {bulletContent}
          </Box>
        </Box>
      )}
      {(heading || text || action) && (
        <Box
          mt={2}
          alignItems="center"
          justifyContent="center"
          flex={1}
          width="100%"
        >
          <H3 textAlign="center" mb={0}>
            {heading}
          </H3>
          {text && (
            <P textAlign="center" mt={2} mb={0}>
              {text}
            </P>
          )}
          {action &&
            (clickable ? (
              <Text
                fontWeight="semiBold"
                lineHeight="inherit"
                mt={2}
                color="primary"
              >
                {action.children}
              </Text>
            ) : (
              <Button mt={2} variant="subtle" {...action} />
            ))}
        </Box>
      )}
    </ClickableBulletWrapper>
  </Box>
);
