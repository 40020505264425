import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';

type Direction = 'swipe-right' | 'swipe-left';
const THRESHOLD = 50;

interface GestureProps {
  onSwipe: (direction: Direction) => void;
}
export const SwipeGesture = ({
  onSwipe,
  children,
}: React.PropsWithChildren<GestureProps>) => {
  const startX = React.useRef(0);
  const currentX = React.useRef(0);

  const touchStart = React.useCallback((e: React.TouchEvent<HTMLElement>) => {
    const touch = e.touches[0];

    if (!touch) return;

    const { clientX } = touch;

    startX.current = clientX;
    currentX.current = clientX;
  }, []);

  const touchMove = React.useCallback((e: React.TouchEvent<HTMLElement>) => {
    const touch = e.touches[0];

    if (!touch) return;

    const { clientX } = touch;

    currentX.current = clientX;
  }, []);

  const touchEnd = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    Math.abs(startX.current - currentX.current) > THRESHOLD &&
      (startX.current < currentX.current
        ? onSwipe('swipe-right')
        : onSwipe('swipe-left'));
  }, [onSwipe]);

  return (
    <Box
      width="100%"
      height="100%"
      onTouchStart={touchStart}
      onTouchMove={touchMove}
      onTouchEnd={touchEnd}
    >
      {children}
    </Box>
  );
};
