import * as React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import type { Document } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Text } from '@mentimeter/ragnar-ui';
import type { JSX } from 'react';
import { Bold, Italic } from '../ui/typography';

export const options: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Text
        mb={[2, 3]}
        extend={() => ({
          ':empty': { display: 'none' },
          ':last-child': { marginBottom: '0px' },
        })}
      >
        {children}
      </Text>
    ),
  },
};

interface Props {
  document: Document;
  rendererOptions?: Options;
}

export function DescriptionRenderer({
  document,
  rendererOptions = options,
}: Props): JSX.Element {
  return documentToReactComponents(document, rendererOptions) as JSX.Element;
}
