import { useTranslation } from 'react-i18next';
import { useMatch } from '@mentimeter/ragnar-device';
import { useAppState } from '../../appState';
import { ActionGroup } from '../actions/ActionGroup';
import { Limit, Section, Wide } from '../layout';
import type { ThemeT } from '../Theme';
import { H2, Subheading } from '../typography';
import type { ActionT } from '../actions/Action';

interface CTAT {
  actions?: Array<ActionT> | undefined;
  heading?: string | undefined;
  subheading?: string | undefined;
}

export type CTAPropsT = ThemeT & CTAT;

const useDefaultProps = ({ heading, subheading, actions }: CTAT) => {
  const { user } = useAppState();
  const { t } = useTranslation('common');

  return {
    heading: heading || t('common:home_page.cta_impress_heading'),
    subheading: subheading || '',
    actions: actions || [
      {
        children: user
          ? t('common:page_links.home')
          : t('common:home_page.hero.cta'),
        href: user ? '/app' : '/auth/signup',
        size: 'large',
      } as ActionT,
    ],
  };
};

export function CTA({ theme = { colors: 'pattern' }, ...props }: CTAPropsT) {
  const { heading, subheading, actions } = useDefaultProps(props);
  const isDesktop = useMatch({ greaterThan: 2 }); // 961px+

  return (
    <Section theme={theme}>
      <Wide alignItems="center">
        <Limit alignItems="center" mb={4}>
          <H2
            textAlign="center"
            fontWeight={isDesktop ? 'regular' : 'semiBold'}
            fontSize={[
              '100', // 16
              '150', // 24
              '200', // 32
              '225', // 36
            ]}
          >
            {heading}
          </H2>
          {subheading && (
            <Subheading textAlign="center" mt={2}>
              {subheading}
            </Subheading>
          )}
        </Limit>
        <ActionGroup actions={actions} />
      </Wide>
    </Section>
  );
}
