import * as React from 'react';
import { Device } from '@mentimeter/ragnar-device';
import { Box, Button, type BoxT } from '@mentimeter/ragnar-ui';
import { Theme } from '@mentimeter/ragnar-ui';
import { TrackingMethods } from '@mentimeter/google-tracking';
import { DecoratedImage } from '../DecoratedImage';
import type { ThemeT } from '../Theme';
import { CONTENTFUL_THEMES } from '../Theme';
import { H2, P } from '../typography';

export interface WebinarT extends BoxT, ThemeT {
  title: string;
  subheading: string;
  image: string;
  date: string | undefined;
  badge?: string | undefined;
  slug: string;
  livestormUrl?: string | undefined;
}

const handleClick = (name: string) => {
  TrackingMethods.trackWebinar(name);
};
export const Webinar = ({
  title,
  subheading,
  image,
  date,
  badge,
  slug,
  theme,
  livestormUrl,
  ...rest
}: WebinarT) => (
  <>
    <Device.Match lessThan={2}>
      <WebinarCompact
        title={title}
        subheading={subheading}
        image={image}
        badge={badge}
        slug={slug}
        theme={theme}
        date={date}
        livestormUrl={livestormUrl}
        {...rest}
      />
    </Device.Match>
    <Device.Match greaterThan={1}>
      <Theme theme={CONTENTFUL_THEMES.dark}>
        <Box
          bg="bg"
          width="100%"
          flexDirection="row"
          alignItems="stretch"
          minHeight="420px"
          overflow="hidden"
          extend={({ theme }) => ({
            borderRadius: `${theme.space[4]}px`,
          })}
          {...rest}
        >
          <Box flex="62%" p={4} justifyContent="space-between">
            <Box>
              <P
                mb={2}
                textTransform="uppercase"
                extend={() => ({
                  letterSpacing: '4px',
                })}
              >
                {badge}
              </P>
              <Theme
                theme={
                  theme
                    ? CONTENTFUL_THEMES[theme.colors]
                    : CONTENTFUL_THEMES.brand
                }
              >
                <H2 color="bg">{title}</H2>
              </Theme>
              <Box mb={4}>
                <P mb={0}>{subheading}</P>
              </Box>
            </Box>
            <Box>
              {!livestormUrl && (
                <Theme
                  theme={
                    theme
                      ? CONTENTFUL_THEMES[theme.colors]
                      : CONTENTFUL_THEMES.brand
                  }
                >
                  <P fontWeight="semiBold" color="bg" mb={3}>
                    {date
                      ? `Next session: ${date}`
                      : 'No upcoming dates at the moment'}
                  </P>
                </Theme>
              )}
              {(livestormUrl || slug) && (
                <Button
                  onClick={() => handleClick(title)}
                  variant="primary"
                  href={livestormUrl || `/webinars/${slug}`}
                  extend={({ theme }) => ({
                    backgroundColor: theme.colors.text,
                    color: theme.colors.bg,
                  })}
                >
                  Register now
                </Button>
              )}
            </Box>
          </Box>

          <Box
            width="38%"
            justifyContent="flex-end"
            zIndex={1}
            height="111%"
            extend={() => ({
              transform: 'translateY(-9%)',
            })}
          >
            <DecoratedImage
              src={image}
              alt="Image of the host"
              extend={() => ({
                objectFit: 'cover',
              })}
              loading="eager"
            />
          </Box>
          <Theme
            theme={
              theme ? CONTENTFUL_THEMES[theme.colors] : CONTENTFUL_THEMES.brand
            }
          >
            <Box
              bg="bg"
              position="absolute"
              minHeight="180%"
              width="45%"
              extend={() => ({
                right: '-14%',
                top: '-40%',
                transform: 'rotate(-20deg)',
              })}
            />
          </Theme>
        </Box>
      </Theme>
    </Device.Match>
  </>
);

export const WebinarCompact = ({
  title,
  subheading,
  image,
  date,
  badge,
  slug,
  theme,
  livestormUrl,
  ...rest
}: WebinarT) => (
  <Theme theme={CONTENTFUL_THEMES.dark}>
    <Box
      bg="bg"
      width={['100%', '48%', '48%', '31%']}
      extend={({ theme }) => ({
        borderRadius: `${theme.space[4]}px`,
      })}
      overflow="hidden"
      {...rest}
    >
      <Box
        flexDirection="row"
        alignItems="stretch"
        overflow="hidden"
        width="100%"
      >
        <Box flex="62%" px={4} pt={4}>
          <Box flex={1} justifyContent="flex-end">
            <P
              m={0}
              mb={0}
              fontSize={2}
              textTransform="uppercase"
              extend={() => ({
                letterSpacing: '4px',
              })}
            >
              {badge}
            </P>
          </Box>
        </Box>
        <Box
          width="38%"
          justifyContent="flex-end"
          zIndex={1}
          height="111%"
          extend={() => ({
            transform: 'translateY(-9%)',
          })}
        >
          <DecoratedImage
            src={image}
            alt="Image of the host"
            extend={() => ({
              objectFit: 'cover',
            })}
            loading="eager"
          />
        </Box>
        <Theme
          theme={
            theme ? CONTENTFUL_THEMES[theme.colors] : CONTENTFUL_THEMES.brand
          }
        >
          <Box
            bg="bg"
            position="absolute"
            minHeight="180%"
            width="45%"
            extend={() => ({
              right: '-14%',
              top: '-40%',
              transform: 'rotate(-20deg)',
            })}
          />
        </Theme>
      </Box>
      <Box p={4} width="100%" justifyContent="space-between" flex={1}>
        <Box>
          <Theme
            theme={
              theme ? CONTENTFUL_THEMES[theme.colors] : CONTENTFUL_THEMES.brand
            }
          >
            <H2 color="bg">{title}</H2>
          </Theme>
          <Box mb={3} overflow="hidden">
            <P mb={0}>{subheading}</P>
          </Box>
        </Box>
        <Box>
          {!livestormUrl && (
            <Theme
              theme={
                theme
                  ? CONTENTFUL_THEMES[theme.colors]
                  : CONTENTFUL_THEMES.brand
              }
            >
              <P mb={3} fontSize={2} fontWeight="semiBold" color="bg">
                {date ? date : 'No upcoming dates at the moment'}
              </P>
            </Theme>
          )}
          {(livestormUrl || slug) && (
            <Button
              variant="primary"
              onClick={() => handleClick(title)}
              href={livestormUrl || `/webinars/${slug}`}
            >
              Register now
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  </Theme>
);
