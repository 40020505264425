import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  type JSX,
} from 'react';
import { animated, useSpring } from 'react-spring';
import { useDevice } from '@mentimeter/ragnar-device';
import { SwipeGesture } from '@mentimeter/swipe-gesture';
import { Box, Clickable } from '@mentimeter/ragnar-ui';
import { ChevronLeftIcon, ChevronRightIcon } from '@mentimeter/ragnar-visuals';
import { chunkElementArray } from 'src/utils/formatArray';
import { Section, Wide } from '../../layout';
import { H1 } from '../../typography';
import Card, { cardMarginDesktop, cardMarginMobile } from './Card';
import HeroCarousel from './HeroCarousel';
import CarouselIndicators, {
  INDICATOR_DEFAULT_DESKTOP_OFFSET,
  INDICATOR_DEFAULT_MOBILE_OFFSET,
} from './CarouselIndicator';

interface ICarousel {
  title?: string | undefined;
  content: JSX.Element[];
  initialActiveItem?: number;
  variant?: 'hero' | 'default';
}

export const getNextSlide = (cur: number, len: number) =>
  cur >= len - 1 ? 0 : cur + 1;
export const getPrevSlide = (cur: number, len: number) =>
  cur <= 0 ? len - 1 : cur - 1;

export function Carousel({
  title,
  content,
  initialActiveItem = 0,
  variant = 'default',
}: ICarousel) {
  const [activeItem, setActiveItem] = useState(initialActiveItem);
  const carouselWrapperRef = useRef<HTMLDivElement | null>(null);
  const { breakpoint } = useDevice();
  const [width, setWidth] = React.useState(0);
  const getSlidePosition = useCallback(
    (nextIndex: number) =>
      -nextIndex *
      (width + (breakpoint > 1 ? cardMarginDesktop : cardMarginMobile)),
    [breakpoint, width],
  );
  const [carouselStyles, setCarouselStyles] = useSpring(() => ({
    x: getSlidePosition(activeItem),
  }));

  useEffect(() => {
    setCarouselStyles.start({ x: getSlidePosition(activeItem) });
  }, [activeItem, getSlidePosition, setCarouselStyles]);
  useEffect(() => {
    if (!carouselWrapperRef.current) {
      return;
    }
    const setActualWidth = () => {
      if (carouselWrapperRef.current) {
        setWidth(carouselWrapperRef.current.clientWidth);
      }
    };

    setActualWidth();
    window.addEventListener('resize', setActualWidth);

    return () => window.removeEventListener('resize', setActualWidth);
  }, [carouselWrapperRef]);

  if (variant === 'hero') {
    return (
      <HeroCarousel
        content={content}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        carouselStyles={carouselStyles}
        carouselWrapperRef={carouselWrapperRef}
        width={width}
      />
    );
  }
  const slidesAmount = breakpoint > 3 ? 3 : breakpoint > 2 ? 2 : 1;
  const slides = chunkElementArray(content, slidesAmount);
  const isFirst = activeItem !== 0;
  const isLast = activeItem !== slides.length - 1;
  const prevSlide = getPrevSlide(activeItem, slides.length);
  const nextSlide = getNextSlide(activeItem, slides.length);

  return (
    <Section display="flex">
      <Wide alignItems="center">
        {title && (
          <H1 textAlign="center" mb={5}>
            {title}
          </H1>
        )}
        <Box
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          width={1}
        >
          {isFirst && (
            <Clickable
              aria-label={`Go to slide ${prevSlide + 1} of ${slides.length}`}
              onClick={() => setActiveItem(prevSlide)}
              display={['none', null, 'flex']}
              position="absolute"
              left={[null, null, -40]}
              zIndex={[10]}
            >
              <ChevronLeftIcon size={4} />
            </Clickable>
          )}
          <Box width={1} ref={carouselWrapperRef}>
            <SwipeGesture
              onSwipe={(direction) =>
                direction === 'swipe-left'
                  ? setActiveItem(nextSlide)
                  : setActiveItem(prevSlide)
              }
            >
              <animated.div
                style={{
                  minWidth: '100%',
                  display: 'flex',
                  alignItems: 'stretch',
                  flexDirection: 'row',
                  ...carouselStyles,
                }}
              >
                {slides.map((slide, index) => (
                  <Card
                    key={index}
                    width={width}
                    hide={Boolean(index && !width)}
                  >
                    {slide}
                  </Card>
                ))}
              </animated.div>
            </SwipeGesture>
          </Box>
          {isLast && (
            <Clickable
              aria-label={`Go to slide ${nextSlide + 1} of ${slides.length}`}
              onClick={() => setActiveItem(nextSlide)}
              display={['none', null, 'flex']}
              position="absolute"
              right={[null, null, -40]}
            >
              <ChevronRightIcon size={4} />
            </Clickable>
          )}
        </Box>
        <Box
          flexDirection="row"
          justifyContent="center"
          width="100%"
          mt={[4, null, 5]}
        >
          <CarouselIndicators
            length={slides.length}
            activeItem={activeItem}
            onChangeSlide={setActiveItem}
            offset={
              breakpoint > 1
                ? INDICATOR_DEFAULT_DESKTOP_OFFSET
                : INDICATOR_DEFAULT_MOBILE_OFFSET
            }
          />
        </Box>
      </Wide>
    </Section>
  );
}
