export type PresentationMode =
  | 'presentation'
  | 'edit'
  | 'present-preview'
  | 'invite'
  | 'teaminvite'
  | 'public'
  | 'embed'
  | 'ably'
  | 'follower'
  | 'voter-follower'
  | 'integrated'
  | 'participant'
  | 'results'
  | 'present'
  | 'old-powerpoint-addin'
  | 'screenshot'
  | 'view';

export interface PresentationRoute {
  seriesId: string;
  questionId?: string | undefined | null;
  mode: PresentationMode;
  session?: 'trends' | number | null;
}

export function buildPresentationPath(
  route: Omit<PresentationRoute, 'mode'> & { mode?: PresentationMode },
) {
  if (route.mode === 'view') {
    const searchParams = new URLSearchParams();
    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }
    const query = searchParams.toString();
    return `/app/presentation/n/${route.seriesId}/view${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'results') {
    return `/app/presentation/n/${route.seriesId}/results`;
  }

  if (route.mode === 'screenshot') {
    return `/app/presentation/n/${route.seriesId}/screenshot/view?question=${route.questionId}`;
  }

  if (route.mode === 'edit') {
    let url = `/app/presentation/n/${route.seriesId}/edit`;
    if (route.questionId && route.questionId !== '0') {
      url += `?question=${route.questionId}`;
    }
    return url;
  }

  if (route.mode === 'present') {
    const searchParams = new URLSearchParams();

    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }

    if (isSessionValid(route.session)) {
      searchParams.set('session', String(route.session));
    }

    const query = searchParams.toString();

    return `/app/presentation/n/${route.seriesId}/present${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'old-powerpoint-addin') {
    const searchParams = new URLSearchParams();
    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }
    const query = searchParams.toString();
    return `/app/presentation/n/${route.seriesId}/old-powerpoint-addin${query.length > 0 ? `?${query}` : ''}`;
  }

  if (route.mode === 'present-preview') {
    const searchParams = new URLSearchParams();
    if (route.questionId) {
      searchParams.set('question', route.questionId);
    }
    const query = searchParams.toString();
    return `/app/presentation/n/${route.seriesId}/present-preview${query.length > 0 ? `?${query}` : ''}`;
  }

  let url = `/app/presentation/${route.seriesId}`;

  if (route.questionId && route.questionId !== '0')
    url += `/${route.questionId}`;
  if (route.mode && route.mode !== 'presentation') url += `/${route.mode}`;

  if (isSessionValid(route.session)) url += `?session=${route.session}`;

  return url;
}

/**
 * Parse url fragments to info about presentation
 * @param pathname Pathname **excluding** the search parameters
 * @param searchParams search parameters, or null
 */
export function parsePresentationPathAndSearchParams(
  pathname: string,
  searchParams: URLSearchParams | null,
): PresentationRoute {
  const pathParts = pathname.replace(/\/$/, '').split('/');

  const migratedViews = ['edit', 'results'];
  const migratedView = pathParts[5] || '';

  if (searchParams?.has('question') || migratedViews.includes(migratedView)) {
    const [, , , , seriesId, view] = pathParts;
    if (view !== 'screenshot') {
      const questionId = searchParams?.get('question');
      const session = searchParams?.get('session');

      const route: PresentationRoute = {
        seriesId: seriesId || '',
        mode: (view ?? 'edit') as PresentationMode,
      };

      if (questionId) route.questionId = questionId;

      if (session) {
        route.session = isNaN(Number(session))
          ? (session as PresentationRoute['session']) ?? null
          : Number(session);
      }
      return route;
    }
  }

  let [, , , seriesId, questionId, view] = pathParts;

  // Handle cases where the URL does not contain a questionId, but is in embed or public mode.
  // Example: /app/presentation/abc123/embed. This is valid, but should eventually redirect to
  // the url containing the questionId.
  if (
    questionId === 'embed' ||
    questionId === 'public' ||
    questionId === 'invite'
  ) {
    view = questionId;
    questionId = undefined;
  }

  if (seriesId === 'n') {
    /**
     * Example: /app/presentation/n/[seriesId]/present?question=[questionId]
     */
    const [, , , , nSeriesId, nView] = pathParts;
    seriesId = nSeriesId;
    view = nView;
    questionId = undefined;
  }

  const route: PresentationRoute = {
    seriesId: seriesId || '',
    mode: (view ?? 'presentation') as PresentationMode,
  };
  if (questionId) route.questionId = questionId;

  const sessionParam = searchParams?.get('session');

  if (sessionParam === 'trends') {
    // Trends and sessions cannot be enabled at the same time, so we use the same query param
    // and parse "?session=trends" as trends being enabled
    route.session = 'trends';
  } else if (sessionParam) {
    // Otherwise we parse the session number
    const parsedSessionNumber = parseInt(sessionParam, 10);
    if (isSessionValid(parsedSessionNumber))
      route.session = parsedSessionNumber;
  }

  return route;
}

export function parsePresentationUrl(href: string): PresentationRoute {
  const url = new URL(href);
  return parsePresentationPathAndSearchParams(url.pathname, url.searchParams);
}

export function parsePresentationSession(
  sessionParam: string | undefined | null,
) {
  if (!sessionParam) return null;
  if (sessionParam === 'trends') {
    return 'trends';
  }
  const parsedSessionNumber = Number(sessionParam);
  if (isSessionValid(parsedSessionNumber)) return parsedSessionNumber;
  return null;
}

function isSessionValid(session: PresentationRoute['session']) {
  return session === 'trends' || Number.isInteger(session);
}
