import type { Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import ContentfulNextImage from 'src/components/Image/ContentfulNextImage';
import { ContactUsForm } from 'src/components/ContactUs';
import { Box } from '@mentimeter/ragnar-ui';
import type { MotionT } from '../ui/Motion';
import { Motion } from '../ui/Motion';
import { Template } from '../ui/Template';
import { Video } from '../ui/Video';
import { DownloadGatedContent } from '../components/notifyUser';
import { getTemplatePageProps, getTemplateProps } from './utils/utils';

const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const id = node.data.target?.sys.contentType?.sys.id;
      switch (id) {
        case 'image': {
          const { fields } = node.data.target;
          if (!fields?.image.fields?.file) {
            return null;
          }
          return <ContentfulNextImage image={fields} />;
        }
        case 'template': {
          const { fields } = node.data.target;
          if (!fields?.presentationId) {
            return null;
          }
          const templateProps = getTemplateProps(fields);
          return <Template {...templateProps} />;
        }
        case 'templatePage': {
          const { fields } = node.data.target;
          if (!fields?.presentationId) {
            return null;
          }

          const templateProps = getTemplatePageProps(fields);
          return <Template {...templateProps} />;
        }
        case 'video': {
          const { fields } = node.data.target;
          if (!fields?.url) {
            return null;
          }
          const videoProps = {
            preview: fields.preview?.fields?.file.url,
            url: fields.url,
            caption: fields.caption,
            title: fields.title,
          };
          return <Video {...videoProps} />;
        }
        case 'motion': {
          const { fields } = node.data.target;
          if (!fields?.motion || !fields.motion.fields) {
            return null;
          }
          const motionProps: MotionT = {
            src: fields.motionUrl || fields.motion.fields.file.url,
            posterSrc: fields.poster?.fields?.file.url,
          };
          return <Motion {...motionProps} />;
        }
        case 'gatedContent': {
          const { fields } = node.data.target;
          const trackingEventName =
            fields.trackingEventName && fields.trackingEventName;
          const salesforceCampaignId =
            fields.salesforceCampaignId && fields.salesforceCampaignId;
          const fileUrl = fields.action?.fields?.href;

          return (
            <Box width="100%" alignItems="center">
              <Box width={['100%', '400px']} alignItems="center">
                <DownloadGatedContent
                  fileUrl={fileUrl}
                  salesforceCampaignId={salesforceCampaignId}
                  trackingEvent={trackingEventName}
                />
              </Box>
            </Box>
          );
        }
        case 'blockReference': {
          const { fields } = node.data.target;

          if (fields?.referenceId === 'enterprise-form') {
            return <ContactUsForm pb={2} />;
          }
          return null;
        }
        default:
          return null;
      }
    },
  },
};

interface Props {
  document: Document;
}

export function MediaRenderer({ document }: Props): any {
  return documentToReactComponents(
    {
      ...document,
      content: document.content
        .filter((x) => x.data.target)
        .map((x) => ({
          ...x,

          id: x.data.target?.sys.contentType?.sys.id,
        })),
    },
    options,
  );
}
