'use client';
import * as React from 'react';
import { TrackingMethods } from '@mentimeter/google-tracking';
import { Box, Button, type BoxT, type VariantT } from '@mentimeter/ragnar-ui';
import { Modal } from '@mentimeter/ragnar-ui';
import { H2, P } from '../ui/typography';
import SalesForceForm from './SalesForceForm';

interface ContactUsModalT {
  children?:
    | React.ReactNode
    | ((arg0: { onClick: () => void }) => React.ReactNode);
  onClick?: () => void;
  text?: string | undefined;
  variant?: VariantT;
  size?: 'large' | 'default' | 'compact';
}

/* Use this if a modal is wanted to open the form*/
export const ContactUs = ({
  children,
  onClick,
  text = 'Contact us',
  variant = 'tertiary',
  size = 'large',
}: ContactUsModalT) => {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <Modal
        show={show}
        theme="light"
        onDismiss={() => setShow(false)}
        width="auto"
        center
        mr={0}
      >
        <ContactUsForm p={3} placement="cta" />
      </Modal>
      {typeof children === 'function' ? (
        children({ onClick: () => setShow(true) })
      ) : (
        <Button
          variant={variant}
          size={size}
          onClick={() => {
            onClick?.();
            setShow(true);
          }}
        >
          {text}
        </Button>
      )}
    </>
  );
};

interface ContactUsFormProps extends BoxT {
  placement?: string;
}

/* Use this if only the form is wanted */
export const ContactUsForm = ({
  placement = 'hero',
  ...rest
}: ContactUsFormProps) => {
  const [hasSentForm, setHasSentForm] = React.useState(false);

  const onHasSentForm = React.useCallback(
    (email: string) => {
      setHasSentForm(true);
      TrackingMethods.trackGenerateLead(placement, email);
    },
    [placement],
  );

  return (
    <Box borderRadius={1} overflow="hidden" {...rest}>
      <Box maxWidth="400px" alignItems="center">
        {hasSentForm ? (
          <>
            <H2>Thank you!</H2>
            {/* TODO Confirm sucess message */}
            <P textAlign="center" mb={0}>
              We will reach out to you shortly.
            </P>
          </>
        ) : (
          <SalesForceForm onHasSentForm={onHasSentForm} placement={placement} />
        )}
      </Box>
    </Box>
  );
};
